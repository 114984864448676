<template>
  <div class="update-container rounded-[10px] bg-white p-20">
    <div class="update-header mb-15 flex items-center justify-between">
      <div class="flex items-center">
        <i class="iconfont icon-gengxin mr-10 text-20 text-gray"></i>
        <span class="text-16">{{ $t('更新日志') }}</span>
      </div>
      <div class="text-gray">
        <a :href="localePath('/update')">{{ $t('历史更新') }}>></a>
      </div>
    </div>
    <div class="update-content">
      <div class="update-item relative mb-20 pl-30">
        <div class="update-title relative mb-20">
          <div class="dot"></div>
          <div class="line"></div>
          <div class="content">{{ dayjs(props.info.releaseTime * 1000).format('YYYY MM-DD') }}</div>
        </div>
        <div v-for="(item, index) in props.info?.contents" :key="index" class="update-title relative mb-20">
          <div class="dot" :class="getDotColor(item)"></div>
          <div class="line"></div>
          <div class="content" v-html="item.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
const props = defineProps({
  info: {
    type: Object,
    required: true
  }
})

const getDotColor = item => {
  switch (item.contentType) {
    case 1:
      return 'orange'
    case 2:
      return 'gray'
    case 3:
      return 'green'
    case 4:
      return 'blue'
  }
}
</script>

<style lang="scss" scoped>
.update-content {
  .update-item {
    .dot {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fff;
      border: 3px solid #0252d9;
      position: absolute;
      left: -25px;
      top: 3px;
      z-index: 2;
      &.green {
        border-color: #0dab62;
      }
      &.gray {
        border-color: #979cae;
      }
      &.orange {
        border-color: #fd802c;
      }
      &.blue {
        border-color: #acc6f1;
      }
    }
    .line {
      width: 1px;
      height: calc(100% + 10px);
      background-color: #eff1f5;
      position: absolute;
      left: -17.5px;
      top: 15px;
      z-index: 1;
    }
    .update-title {
      &:last-child {
        .line {
          display: none;
        }
      }
    }
  }
}
</style>
